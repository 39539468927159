import React from "react"
import loadable from "@loadable/component"

import Layout from "../components/Layout"

import geoJson from "../data/la-boundaries-2019-merged.json"
import initialData from "../api-data/cases-ltla.json"
const BaseMap = loadable(() => import("../components/BaseMap"))

const IndexPage = ({ location }) => (
  <Layout pathname={location.pathname} title={"Cases – map"}>
    <BaseMap
      initialData={initialData}
      apiPath="/cases/ltla"
      label="cases"
      geoJson={geoJson}
      codeProp="lad19cd"
      nameProp="lad19nm"
      bounds={[
        [49.5, -9.6],
        [61, 1.7],
      ]}
    />
  </Layout>
)

export default IndexPage
